import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class FallosService {
  paginate (params) {
    return http.get(`${baseUrl}/desacatos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/desacatos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/desacatos/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/desacatos/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  remove (id) {
    return http.delete(`${baseUrl}/desacatos/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  desacatosTutela (id, params) {
    return http.get(`${baseUrl}/desacatos/sanciones-tutela/${id}`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
