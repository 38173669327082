import { createStore } from 'vuex'
import TiposProcesosService from '../services/tiposProcesos.service'

const _TiposProcesosService = new TiposProcesosService()
const storeTiposProcesos = {
  state: {

  },
  getters: {

  },
  mutations: {
  },
  actions: {
    getPaginate ({ commit }, payload) {
      return _TiposProcesosService.paginate(payload).then((response) => {
        return response
      })
    }
  }
}
const store = createStore(storeTiposProcesos)
export default {
  namespaced: true,
  ...store
}
