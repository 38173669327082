import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class TiposProcesosService {
  paginate (params) {
    return http.get(`${baseUrl}/tipos-procesos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
